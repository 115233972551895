<script lang="ts" setup>
import Links from "../Links.vue";
import Logo from "../Logo.vue";
import UserMenue from "../UserMenue.vue";

const activeLinks = useActiveLinks();

const claimAnchors = [
  {
    title: "Search",
    id: "search",
    icon: "lucide:search"
  },
  {
    title: "Document",
    id: "document",
    icon: "lucide:file-text"
  },
  {
    title: "Chat",
    id: "chat",
    icon: "lucide:message-circle"
  }
];

const claimsAnchors = [
  {
    title: "Search",
    id: "search",
    icon: "lucide:search"
  },
  {
    title: "Liste",
    id: "grid",
    icon: "lucide:list"
  }
];
const handbookAnchors = [
  {
    title: "Kapitel",
    id: "handbook",
    icon: "lucide:table-of-contents"
  },
  {
    title: "Checkbot",
    id: "chat",
    icon: "lucide:bot"
  }
];

const forumAnchors = [
  {
    title: "Channels",
    id: "forum-channels",
    icon: "lucide:table-of-contents"
  },
  {
    title: "Chat",
    id: "chat",
    icon: "lucide:message-circle"
  }
];
const route = useRoute();
const pageAnchors = computed(() => {
  if (route.name === "claim" || route.name === "submission") {
    return claimAnchors;
  }
  if (route.name === "claims" || route.name === "submissions") {
    return claimsAnchors;
  }
  if (route.name === "handbook" || route.name === "handbook-page") {
    return handbookAnchors;
  }
  if (route.name === "forum") {
    return forumAnchors;
  }
  return [];
});
const navigationStore = useNavigationStore();

const open = ref(false);
const router = useRouter();
watch(router.currentRoute, () => {
  open.value = false;
});

function scrollIntoView(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}
</script>

<template>
  <nav
    :class="
      cn(
        'xl:hidden fixed z-40 top-0 h-12 bg-neutral-0 border-b inset-x-0',
        'pl-5 pr-2 flex items-center justify-between gap-4'
      )
    "
  >
    <Logo class="block w-36 mt-2.5" />
    <clientOnly>
      <NavigationBreadcrumb v-if="navigationStore.pageTitle === 'breadcrumb'" />
      <h1 v-else class="h1">{{ navigationStore.pageTitle }}</h1>
    </clientOnly>
  </nav>
  <nav
    :class="
      cn(
        'xl:hidden fixed z-40 bottom-0 inset-x-0 h-12',
        'flex items-center justify-center',
        'bg-neutral-0 border-t'
      )
    "
  >
    <UserMenue class="absolute left-2" />

    <ul class="flex items-center gap-1 h-12 p-1 border-t">
      <li v-for="link in pageAnchors" :key="link.title">
        <BasicButton
          :icon="link.icon"
          :variant="navigationStore.view === link.id ? 'default' : 'white'"
          :class="cn({ 'pointer-events-none': navigationStore.view === link.id })"
          @click="
            () => {
              navigationStore.view = link.id;
              scrollIntoView(link.id);
            }
          "
        />
      </li>
    </ul>
    <BasicButton icon="lucide:menu" class="absolute top-1 right-1" @click="open = true" />
  </nav>
  <BasicDialog
    v-model:open="open"
    @close="
      () => {
        open = false;
      }
    "
  >
    <Links isMobile />
  </BasicDialog>
</template>
