<script lang="ts" setup>
import Links from "../Links.vue";
import Logo from "../Logo.vue";
import UserMenue from "../UserMenue.vue";
import Filters from "./Filters.vue";

defineProps<{
  class?: string;
}>();
const hasAccess = useHasAccess();
const target = ref();
usePageNavigation(target, "search");

const isSubmissionOrClaimPage = useIsSubmissionOrClaimPage();
</script>

<template>
  <nav
    id="search"
    ref="target"
    :class="
      cn(
        'w-60',
        'hidden xl:flex flex-col items-center',
        'h-full shrink-0 group',
        'bg-neutral-0 border-r border-neutral-4',
        'max-xl:snap-start',
        {
          '!flex': isSubmissionOrClaimPage
        },
        $props.class
      )
    "
  >
    <Logo
      :class="
        cn('max-xl:hidden relative flex items-center justify-center p-5 pt-[1.9em] w-full border-b h-[100px]')
      "
    />
    <div class="grow w-full scroll-area">
      <div class="flex flex-col gap-5 py-2.5">
        <Links class="max-xl:hidden" />
        <Filters v-if="hasAccess.junior" />
      </div>
    </div>
    <footer :class="cn('max-xl:hidden w-full h-16 border-t flex items-center gap-2.5 px-5')">
      <UserMenue />
    </footer>
  </nav>
</template>
