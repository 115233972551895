<script lang="ts" setup>
import NavElement from "./NavElement.vue";
import type { NavigationLink } from "~/types";

defineProps<{
  class?: string;
  isMobile?: boolean;
}>();

const links = useNavigationLinks();
</script>

<template>
  <ul :class="cn('w-full flex flex-col gap-1 px-5', $props.class)">
    <NavElement v-for="link in links" :key="link.to" :link="link as NavigationLink" :level="1" :isMobile />
  </ul>
</template>
