<script setup lang="ts">
import { Icon } from "@iconify/vue";

const isSubmissionOrClaimPage = useIsSubmissionOrClaimPage();

const store = useFilteringStore();

const toggleGroupItemClasses = cn(
  "flex items-center gap-2 text-sm rounded-lg",
  "bg-transparent [&>svg]:hover:border-neutral-4 [&>svg]:hover:bg-neutral-2",
  "text-neutral-6 hover:text-neutral-8 dark:[&>svg]:data-[state=on]:text-neutral-0 dark:[&>span]:data-[state=on]:text-neutral-0"
);

const hasFilters = computed(() => {
  return store.scope !== "all" || store.userClaimState.length > 0 || store.categories.length > 0;
});

const hasAccess = useHasAccess();
</script>

<template>
  <div v-if="isSubmissionOrClaimPage" class="p-2.5 mx-2.5 flex flex-col gap-2 bg-neutral-1 rounded-lg">
    <div class="flex justify-between items-center">
      <h4 class="small uppercase">{{ $t("mainnav.filters.title") }}</h4>
      <BasicButton
        size="xs"
        icon="lucide:x"
        :class="
          cn('bg-neutral-05', {
            'pointer-events-none opacity-0': !hasFilters
          })
        "
        :tooltip="$t('mainnav.filters.reset')"
        @click="store.reset"
      />
    </div>
    <Separator class="bg-neutral-3 h-px w-full" />
    <div v-if="hasAccess.editor" class="flex gap-2 items-center">
      <p class="text-sm">{{ $t("mainnav.filters.scope.title") }}:</p>
      <div class="grow">
        <BasicSelect
          v-model="store.scope"
          size="sm"
          class="w-full"
          :tooltip="$t('mainnav.filters.reset')"
          :options="[
            { label: $t('mainnav.filters.scope.all'), value: 'all' },
            { label: $t('mainnav.filters.scope.editorial'), value: 'editorial' },
            { label: $t('mainnav.filters.scope.community'), value: 'community' }
          ]"
        />
      </div>
    </div>
    <ToggleGroupRoot v-model="store.userClaimState" type="multiple" class="flex flex-col gap-2">
      <ToggleGroupItem
        value="isNew"
        :class="
          cn(
            toggleGroupItemClasses,
            'data-[state=on]:[&>svg]:bg-neutral-7 data-[state=on]:[&>svg]:border-neutral-7 data-[state=on]:[&>svg]:text-neutral-0'
          )
        "
      >
        <Icon
          :icon="ICONS.unread"
          class="transition border border-neutral-3 rounded-lg p-1 size-6 bg-neutral-0"
        />
        {{ $t("mainnav.filters.unread") }}
      </ToggleGroupItem>
      <ToggleGroupItem
        value="isMarked"
        :class="
          cn(
            toggleGroupItemClasses,
            'data-[state=on]:[&>svg]:bg-blue-300 data-[state=on]:[&>svg]:border-blue-300'
          )
        "
      >
        <Icon
          :icon="ICONS.bookmark"
          class="transition border border-neutral-3 rounded-lg p-1 size-6 bg-neutral-0"
        />
        {{ $t("mainnav.filters.marked") }}
      </ToggleGroupItem>
    </ToggleGroupRoot>
    <Separator class="bg-neutral-3 h-px w-full" />
    <Categories
      :selectedCategories="store.categories"
      @add="store.addCategory($event)"
      @remove="store.removeCategory($event)"
    />
  </div>
</template>
